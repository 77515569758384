import { Box, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Home } from "../../pages/Home";




export function WhatsApp() {
    const [message, setMessage] = useState<string>("")
    const [transformedMessage, setTransformedMessage] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [timer, setTimer] = useState<number>(0)

    const fetchTransformedMessage = async () => {
        setLoading(true)
        axios
            .post("https://europe-west9-fndm-finandom.cloudfunctions.net/WhatsApp_Transformer", {}, { params: { message:message } })
            .then((response) => {
                setTransformedMessage(response.data)
                setLoading(false)
            }).catch((error) => {
                console.error(error)
                setLoading(false)
            })
    }

    useEffect(() => {
        // debounce the fetchTransformedMessage every 5 seconds
        clearTimeout(timer)
        const newTimer = window.setTimeout(() => {
            fetchTransformedMessage()
        }, 1000)
        setTimer(newTimer)

    }, [message])

    return <Box>
        <Typography variant="h4">WhatsApp Transformer</Typography>
        <Grid container spacing={2} >
            <Grid item sm={6} xs={12}>
                <TextField
                    label="Message"
                    fullWidth
                    multiline
                    minRows={10}
                    onChange={(e) => {
                        setMessage(e.target.value)
                    }}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <Typography variant="h6">Transformed Message</Typography>
                {
                    loading ?
                        <CircularProgress /> :
                        <div dangerouslySetInnerHTML={{ __html: transformedMessage }} />
                }
            </Grid>
        </Grid>



    </Box>
}

export function HomeWhatsApp() {
    return <Home>
        <WhatsApp />
    </Home>
}