import { Box } from "@mui/material";
import { NavBar } from "../components/UI/NavBar";

export interface HomeProps {
    children?: React.ReactNode;
}

export function Home(props: HomeProps) {
    return <Box id="main">
        <NavBar />
        <Box 
        id="content"
            p={2}>
            {props.children}
        </Box>

    </Box>
}