import { AppBar, Box, Menu, MenuItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export interface fMenu {
    title: string;
    icon?: React.ReactNode;
}

export interface MegaMenu extends fMenu {
    items: fLink[] | MegaMenu[];
}

export interface fLink extends fMenu {
    url: string;
}

export interface NavBarMenuProps {
    menu: fLink | MegaMenu;
}

export function NavBarMenu({ menu }: NavBarMenuProps) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    // if menu is a link
    if ((menu as fLink).url) {
        return <MenuItem onClick={() => {
            navigate((menu as fLink).url);
        }} >{menu.title}</MenuItem>
    }
    // if menu is a MegaMenu
    if ((menu as MegaMenu).items) {
        const _menu = menu as MegaMenu;
        return <MenuItem
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
            }}
            onMouseLeave={() => {
                setAnchorEl(null);
            }}
        >
            {menu.title}
            <Menu id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                // onClose={handleClose}
                onMouseLeave={() => {
                    setAnchorEl(null);
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {_menu.items.map((item, index) => <NavBarMenu key={index} menu={item as fLink} />)}
            </Menu>
        </MenuItem>
    }

    return <></>

}

const navBarMenus: (fLink | MegaMenu)[] = [
    {
        title: "Outils", items: [
            {
                title: "Whatsapp",
                url: "/whatsapp"
            }
        ]
    }
]
export function NavBar() {

    return <AppBar position="static">
        <Typography variant="h6">Finandom</Typography>
        <Box display="flex" flexDirection="row">
            {navBarMenus.map((menu, index) => <NavBarMenu key={index} menu={menu} />)}
        </Box>
    </AppBar>
}