import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/Home";
import { HomeWhatsApp } from "../components/tools/WhatsApp";

export function AppRouter() {
  return <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/whatsapp" element={<HomeWhatsApp/>} />
    </Routes>
  </BrowserRouter>;
}